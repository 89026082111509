import React from "react"
import { PageHeader, PageSubHeader } from "../common/pageHeaders"
import PageText from "../common/pageText"
import style from "./about.module.css"
import Img from "gatsby-image"

const About = ({ professionalHec, firstLove, soulMate, sendCity }) => {
  return (
    <>
      <section>
        <PageHeader>About Me</PageHeader>
        <div className={style.flexBodyLayout}>
          <Img
            fluid={professionalHec}
            className={style.me}
            objectFit="cover"
            objectPosition="center center"
            alt="Professional photo of Hector Benitez"
          />
          <div className={style.bodyLayout}>
            <PageText>
              LA native, who loves to chill at the beach, eat tacos and talk
              about how the Lakers are the 2020 champions after a 10 year
              drought!
            </PageText>
            <PageText>
              Graduated from Syracuse University with a B.A. in Psychology.
              Obviously, I steered away from a life of pseudo-science.
            </PageText>
            <PageText>
              After undergrad, I completed UPenn’s Full Stack development
              program and received a certificate as a M.E.R.N. stack developer.
              June/July 2020, began working with Johnson & Johnson as a
              Front-End Developer.
            </PageText>
          </div>
        </div>
        <PageSubHeader>But who is Hector without Dev?</PageSubHeader>
        <div className={style.moreInfoDiv}>
          <PageText>
            After living on the East coast since 2014, my fiancé and I finally
            moved back to the Golden State. Besides basking in the Californian
            sun, I heavily binge watch Anime, listen to music and geek out over
            a new potential project with a new framework! If I'm not spending
            time with my fiancé, I am most likely rock climbing!
          </PageText>
          <PageText>
            If you want to hear more about me or to meet to climb, reach out and
            send me an email. Other than that please enjoy more pics of me and
            the love of my life...
          </PageText>
          <PageText>my girlfriend is there too.</PageText>
        </div>
        <div className={style.alsoMeContainer}>
          <Img
            fluid={firstLove}
            className={style.alsoMe}
            objectFit="cover"
            objectPosition="center center"
            alt="Hector and his dog, Lucky"
          />
          <Img
            fluid={soulMate}
            className={style.alsoMe}
            objectFit="cover"
            objectPosition="center center"
            alt="Hector and Mailyn, on top of Angels Landing, Zion"
          />
          <Img
            fluid={sendCity}
            className={style.alsoMe}
            objectFit="cover"
            objectPosition="center center"
            alt="Hector climbing at Stoney Point"
          />
        </div>
      </section>
    </>
  )
}

export default About
