import React from "react"
import About from "../components/About"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import { ABOUT_SEO } from "../config/siteMetaData"

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      professionalHec: file(relativePath: { eq: "Professional_photo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      firstLove: file(relativePath: { eq: "luck.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      soulMate: file(relativePath: { eq: "angels_landing.JPG" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sendCity: file(relativePath: { eq: "tr_bethoven.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout {...ABOUT_SEO}>
      <About
        professionalHec={data.professionalHec.childImageSharp.fluid}
        firstLove={data.firstLove.childImageSharp.fluid}
        soulMate={data.soulMate.childImageSharp.fluid}
        sendCity={data.sendCity.childImageSharp.fluid}
      />
    </Layout>
  )
}

export default AboutPage
